@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  line-height: 1.7;
  font-size: 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  background-image: radial-gradient(#01277e 0%, #000207 80%);
  color: white;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.clear:after {
  content: "";
  display: table;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.siteContent-wrapper {
  display: flex;
  align-items: center;
  min-height: 100vh;
}

main {
  width: 100%;
  padding: 2rem 2rem 6rem 2rem;
}

@media (min-width: 30rem) {
  main {
    padding: 2rem;
  }
}

main.results {
  padding: 2rem 2rem 6rem 2rem;
}

main.front,
main.scenario {
  max-width: 100rem;
}

main.content {
  max-width: 60rem;
  margin: 0 auto;
  animation: fadeUp 1s forwards ease;
}

@media (max-width: 50rem) {
  main.scenario {
    padding: 0;
    margin-top: 100vw;
  }
  
  .scene {
    position: relative;
    padding: 2rem 2rem 6rem 2rem;
    background: linear-gradient(
      to bottom,
      rgba(0, 10, 30, 0) 0%,
      rgba(0, 10, 30, 0.75) 10%
    );
  }
}

main.front {
  display: flex;
  height: 100vh;
  align-items: center;
  text-align: center;
}

@media (min-width: 50rem) {
  main.front {
    background: rgba(0, 10, 30, 0.5);
    max-width: 50vw;
    text-align: left;
    justify-content: flex-start;
  }
}

@media(max-width: 50rem) {

  main.front { 
     padding: 0;
  }

  .intro {
    width: 100%;
    padding: 2rem 1rem;
    background: rgba(0, 10, 30, 0.75);
  }
}

.share-bar {
  display: flex;
  text-align: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.share-bar,
.button {
  margin-top: 1.5rem;
  font-family: "Lato", "sans-serif";
  font-weight: 700;
  color: white;
  font-size: 1.5rem;
  padding: 1rem;
  border: 0.1em solid #0066f0;
  background: #0066f0;
  width: 100%;
  transition: 0.5s;
}

.share-bar a {
  display: block;
}

.button {
  border: none;
  cursor: pointer;
}

button:disabled .currentResponse-text {
  display: none;
}

button:disabled .currentResponse-indicator {
  background: transparent;
  border-radius: 100%;
  border-radius: 50%;
  border: 0.2em solid rgba(0, 10, 30, 0.5);
  border-top: 0.2em solid rgba(0, 10, 30, 0.1);
  border-bottom: 0.2em solid rgba(0, 10, 30, 0.1);
  animation: spin 1s infinite 0s linear;
}

button:disabled .currentResponse-indicator-text {
  opacity: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 50rem) {
  .button {
    font-size: 1.25rem;
    text-align: center;
    position: fixed;
    z-index: 3;
    width: 100%;
    left: 0;
    bottom: 0;
    margin: 0;
  }
}

.currentResponse-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0.5rem;
  background: rgba(0, 10, 30, 0.5);
  width: 1.5em;
  height: 1.5em;
  transition: 0.5s;
}

/* Headings */

h1 {
  margin-top: 0;
}

main.scenario h1 {
  font-weight: 300;
  font-size: 1.5rem;
  margin: 1rem 0;
}

@media (max-width: 30rem) {
  main.scenario h1 {
    font-size: 1.25rem;
    margin-top: 2rem;
  }
}

.siteTitle {
  line-height: 1.4;
  text-transform: uppercase;
  margin: 2rem 0;
}

@media (min-width: 30rem) {
  .siteTitle {
    font-size: 3rem;
  }
}

.siteTitle span {
  color: #48f1ff;
}

#brandLogo {
  max-width: 10rem;
}

/* Occupants */

.occupantsList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.occupantsList-item {
  text-align: center;
  width: 25%;
}

/* About */

@media (min-width: 50rem) {
  .panel {
    float: left;
    width: 50%;
  }

  .panel:first-of-type {
    padding-right: 1.5rem;
  }
}

@media (min-width: 30rem) {
  .description {
    background: rgba(0, 10, 30, 0.25);
    padding: 1.5rem;
  }
}

.description-title {
  margin: 0;
}

/* Tabs */

.tab {
  background: rgba(0, 10, 30, 0.5);
  margin: 1rem 0;
  border: 0.1em solid #0066f0;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 600;
  width: 4rem;
  display: inline-block;
  padding: 0.5rem;
  margin-right: 0.5rem;
  vertical-align: top;
  cursor: pointer;
}

.tabInstructions {
  margin-bottom: 1rem;
}

.tabInstructions-title {
  margin: 0;
}

.tab.is-active,
.tab[aria-selected="true"] {
  background: #0066f0;
  cursor: default;
}

@media (max-width: 50rem) {
  .tabs {
    width: 100%;
    position: fixed;
    left: 0;
    height: 6rem;
    text-align: center;
    top: 0;
    margin: 0 auto;
  }

  .tab {
    font-size: 1.5rem;
  }
}

/* Form */

.input-wrapper {
  margin-top: 2rem;
}

select {
  background: #48f1ff;
  border: 0.1em solid #48f1ff;
  border-radius: 0;
  overflow: hidden;
  color: rgb(0, 10, 30);
  font-size: 20px;
  font-weight: bold;
  padding: 1rem;
  width: 100%;
  /* -webkit-appearance: none; */
}

select,
label,
legend {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
}

input {
  border: 0.1em solid;
}

.genderSelect-wrapper {
  display: flex;
}

#genderSelect-male {
  margin-right: 5px;
}

.genderSelect {
  background: rgba(0, 10, 30, 0.2);
  width: 50%;
  text-align: center;
  border: 0.1em solid #48f1ff;
  padding: 0.5rem;
}

.genderSelect-wrapper input[type="radio"]:checked + label {
  background: #48f1ff;
  color: #000207;
  cursor: pointer;
}

.genderSelect-input,
.outcomeA,
.outcomeB {
  position: absolute;
  left: -200%;
  opacity: 0;
}

/* Scenarios */
@media (min-width: 50rem) {
  .scene {
    width: 50%;
    padding-right: 5rem;
  }
}

@media (min-width: 82rem) {
  .scene {
    width: 40%;
  }
}

/* SVG */

.progressBar {
  position: fixed;
  z-index: 10;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 10, 30, 0.5);
}

.progressBar,
.progressBar-bar {
  height: .25em;
}

.progressBar-bar {
  position: absolute;
  left: 0;
  background: #1cf3a0;
  transition: 2.25s;
}

.sceneSvg {
  width: 100%;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
}

.sceneSVG-a,
.sceneSVG-b {
  fill: transparent;
  cursor: pointer;
  transition: 0.5s;
}

.sceneSVG-a:hover,
.sceneSVG-b:hover {
  fill: #0066f0;
  opacity: 0.25;
}

.occupantIcon-container {
  transition: 0.25s;
}

#sceneSVG-wrapper,
#trackAnimated-wrapper {
  position: fixed;
  width: 100%;
  top: 6rem;
  left: 0;
  perspective: 30px;
  margin: 0 auto;
  max-height: 100vh;
  height: 0;
  padding-bottom: 50%;
}

@media (min-width: 50rem) {
  #sceneSVG-wrapper,
  #trackAnimated-wrapper {
    top: 50%;
    left: auto;
    transform: translate(0%, -50%);
    width: 50%;
    right: 2rem;
  }
}

#trackAnimated-wrapper {
  z-index: -2;
}

#sceneSVG-wrapper {
  z-index: 0;
}

#trackSVG {
  transition: 2s;
}

#trackSVG,
.sceneSVG {
  position: absolute;
}

#carIntro {
  opacity: 0.5;
}

.rotate.active {
  animation: rotate 10s alternate infinite;
}

.roadMarking {
  transition: 0.5s;
}

.horzLine.active {
  animation: track 0.25s linear infinite;
}

@keyframes track {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

/* Preference Slider for the results page */

.prefWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem -0.9rem 0 -0.9rem;
}

.prefCard {
  width: 100%;
  padding: .5rem;
}
@media (min-width: 50rem) {
  .prefCard {
    width: 50%;
  }
}

.prefCard-title {
  margin: 0 .4rem;
}

.prefSlider {
  margin: 1rem 0;
}

.prefLabels {
  display: flex;
  justify-content: space-between;
  padding: .4rem;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    margin-top: 1rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(30deg);
  }
}

/* Pointer events none */

#centralReservationSVG,
.arrows-animate,
#crossingSVG,
#carSVG {
  pointer-events: none;
}

@supports (display: grid) {
  #centralReservationSVG {
    overflow: visible;
  }
}

.ring {
  stroke: #48f1ff;
  stroke-width: 0.5;
  animation: dashOffset 4s infinite;
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 1000;
    stroke-dasharray: 500;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 500;
  }
}

